<template>
  <b-modal
    ref="commonModal"
    :title="title"
    :size="size"
    button-size="sm"
    footer-class="format-footer"
    header-class="text-gray-black"
    cancel-variant="danger"
    :no-enforce-focus="true"
    cancel-title="Đóng"
    :hide-footer="true"
    no-close-on-backdrop
    @ok="onHandleOk"
    @cancel="resetForm"
    @close="resetForm"
    @show="resetAuto"
  >
    <validation-observer
      ref="commonRules"
    >
      <slot />
    </validation-observer>

    <template #modal-footer="{ ok, cancel }">
      <slot
        name="footer"
        :ok="ok"
        :cancel="cancel"
      />
    </template>
  </b-modal>
</template>
<script>
import {
  BModal,
} from 'bootstrap-vue'

export default {
  components: {
    BModal,
  },
  props: {
    title: {
      type: String,
      default: '',
    },
    size: {
      type: String,
      default: 'lg',
    },
  },
  data() {
    return {
    }
  },
  methods: {
    showModal() {
      this.$refs.commonModal.show()
    },
    hideModal() {
      this.$refs.commonModal.hide()
      this.resetForm()
    },
    onHandleOk(e) {
      e.preventDefault()
      this.$refs.commonRules.validate().then(success => {
        if (success) {
          if (!this.$slots.default) {
            this.hideModal()
          }
          this.$refs.commonRules.reset()
          this.$emit('handle-ok')
        } else {
          const first = Object.entries(this.$refs.commonRules.errors).find(item => item[1].length > 0)
          this.$emit('handle-focus', first)
        }
      })
    },
    checkValidate() {
      this.$refs.commonRules.validate().then(success => {
        if (success) {
          this.$emit('handle-ok')
        } else {
          const first = Object.entries(this.$refs.commonRules.errors).find(item => item[1].length > 0)
          this.$emit('handle-focus', first)
        }
      })
    },
    resetForm() {
      this.$emit('reset-form')
    },
    resetAuto() {
      this.$emit('reset-auto')
    },
  },
}
</script>
