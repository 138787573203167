<template>
  <b-card>
    <div class="d-flex">
      <!-- <span class="d-flex align-items-center">Chọn loại đồng bộ</span>
      <treeselect
        :options="[]"
        placeholder="Chọn..."
        class="w-50 ml-2"
        no-options-text="Không có dữ liệu"
        no-results-text="Không có dữ liệu"
      /> -->
    </div>
    <good-table
      ref="danhSachDongBo"
      class="mt-1"
      :columns="columns"
      :rows="danhSachDongBo"
      :total="tongSoDongBo"
      :selectable="false"
      @page-change="pageChange"
    >
      <template
        slot="custom-row"
        slot-scope="props"
      >

        <span v-if="props.props.column.field == 'ngayTiepNhan'">
          <div>{{ formatDate(props.props.row) }}</div>
        </span>
        <span v-else-if="props.props.column.field == 'maBienNhan'">
          <a
            href="javascript:;"
            @click="layChiTiet(props.props.row)"
          >{{ `${props.props.row.maBienNhan}` }}</a>
        </span>
      </template>
    </good-table>
    <common-modal
      ref="commonnModal"
      :title="'Chi tiết hồ sơ chuyên ngành'"
      :size="'xl'"
    >
      <component
        :is="componentName === 'XoaForm' ? '' : componentName"
        ref="componentName"
        :data-form="dataForm"
      />
      <div class="text-right">
        <b-button
          variant="danger"
          size="sm"
          @click="closeModal()"
        >
          Đóng
        </b-button>
      </div>
    </common-modal>
  </b-card>
</template>
<script>
import {
  BCard,
  BButton,
} from 'bootstrap-vue'
import addCssSemantic from '@/mixins/mixins'
import GoodTable from '@/components/GoodTable.vue'
import { required } from '@validations'
import END_POINTS from '@/api/endpoints'
import _isEmpty from 'lodash/isEmpty'
import moment from 'moment'
import _cloneDeep from 'lodash/cloneDeep'
import DongBoForm from '@/modules/quan-tri-he-thong/form/DongBoForm.vue'
import CommonModal from '@/modules/quan-tri-he-thong/modal/CommonModal.vue'

export default {
  components: {
    GoodTable,
    BCard,
    CommonModal,
    DongBoForm,
    BButton,
  },
  mixins: [addCssSemantic],
  data() {
    return {
      required,
      isCreate: true,
      columns: [
        {
          label: 'STT',
          thClass: 'text-center custom-style',
          tdClass: 'text-center',
          width: '50px',
          field: 'STT',
        },
        {
          label: 'Mã biên nhận',
          field: 'maBienNhan',
          width: '150px',
          thClass: 'text-center',
        },
        {
          label: 'Mã TTHC',
          field: 'maTTHC',
          thClass: 'text-center',
          width: 'auto',
        },
        {
          label: 'Tên TTHC',
          field: 'tenTTHC',
          thClass: 'text-center',
          width: 'auto',
        },
        {
          label: 'Chủ hồ sơ',
          field: 'chuHoSo',
          thClass: 'text-center',
          width: '250px',
        },
        {
          label: 'Ngày tiếp nhận',
          field: 'ngayTiepNhan',
          width: '180px',
          thClass: 'text-center',
          tdClass: 'text-left',
        },
        {
          label: 'Tên trạng thái',
          field: 'tenTrangThai',
          width: '180px',
          thClass: 'text-center',
          tdClass: 'text-left',
        },
        // {
        //   label: 'Thao tác',
        //   thClass: 'text-center',
        //   tdClass: 'vertical-align-middle',
        //   field: 'actions',
        //   width: '100px',
        // },
      ],
      filter: {
        maBienNhan: '',
        maTTHC: '',
        tenTTHC: null,
        chuHoSo: '',
        ngayTiepNhan: null,
        tenTrangThai: '',
        pageNumber: 1,
        pageSize: 10,
      },
      form: {
        maTrangThai: '',
        tenTrangThai: '',
        loaiTrangThaiId: null,
        status: true,
        dataNavId: [],
      },
      dataForm: {
        tenTTHC: null,
        phiHs: null,
        lePhi: null,
        code: null,
        soBienNhan: null,
        ngayNop: null,
        ngayHenTraKq: null,
        trangThai: null,
        nguoiNop_HoVaTen: null,
        nguoiNop_CMND_HoChieu: null,
        nguoiNop_DiaChi: null,
        nguoiNop_DiaChiDayDu: null,
        nguoiNop_DiaChiEmail: null,
        nguoiNop_HuyenId: null,
        nguoiNop_SoDienThoai: null,
        nguoiNop_TinhId: null,
        nguoiNop_XaId: null,
        chuHoSo_CMND_HoChieu: null,
        chuHoSo_DiaChi: null,
        chuHoSo_DiaChiDayDu: null,
        chuHoSo_DiaChiEmail: null,
        chuHoSo_HoVaTen: null,
        chuHoSo_HuyenId: null,
        chuHoSo_SoDienThoai: null,
        chuHoSo_TinhId: null,
        chuHoSo_XaId: null,
        trangThaiId: null,
      },
      beginObject: {},
      selectedRows: [],
      selectedNumber: 0,
      danhSachDongBo: [],
      treeBoxWidth: '250px',
      isShowFooter: false,
      isShow: false,
      tongSoDongBo: 0,
      errorMessage: '',
      danhSachLoaiTrangThai: [],
      danhSachNav: [],
      componentName: null,
    }
  },
  created() {
    this.filterdanhSachDongBo()
  },
  methods: {
    closeModal() {
      this.$refs.commonnModal.hideModal()
    },
    layChiTiet(data) {
      this.$refs.commonnModal.showModal()
      this.componentName = 'DongBoForm'
      this.$axios.get(END_POINTS.DONG_BO.CHI_TIET,
        { id: data.id },
        false).then(response => {
        // console.log(response.data.data)
        if (response.data) {
          this.dataForm = {
            tenTTHC: response.data.data.tenTTHC,
            phiHs: response.data.data.phiHs,
            lePhi: response.data.data.lePhi,
            code: response.data.data.code,
            soBienNhan: response.data.data.code,
            ngayNop: this.formatDateTime(response.data.data.ngayNop),
            ngayHenTraKq: this.formatDateTime(response.data.data.ngayHenTraKq),
            trangThaiId: data.tenTrangThai,
            nguoiNop_HoVaTen: response.data.data.nguoiNop_HoVaTen,
            nguoiNop_CMND_HoChieu: response.data.data.nguoiNop_CMND_HoChieu,
            nguoiNop_DiaChi: response.data.data.nguoiNop_DiaChi,
            nguoiNop_DiaChiDayDu: response.data.data.nguoiNop_DiaChiDayDu,
            nguoiNop_DiaChiEmail: response.data.data.nguoiNop_DiaChiEmail,
            nguoiNop_HuyenId: response.data.data.nguoiNop_HuyenId,
            nguoiNop_SoDienThoai: response.data.data.nguoiNop_SoDienThoai,
            nguoiNop_TinhId: response.data.data.nguoiNop_TinhId,
            nguoiNop_XaId: response.data.data.nguoiNop_XaId,
            chuHoSo_CMND_HoChieu: response.data.data.chuHoSo_CMND_HoChieu,
            chuHoSo_DiaChi: response.data.data.chuHoSo_DiaChi,
            chuHoSo_DiaChiDayDu: response.data.data.chuHoSo_DiaChiDayDu,
            chuHoSo_DiaChiEmail: response.data.data.chuHoSo_DiaChiEmail,
            chuHoSo_HoVaTen: response.data.data.chuHoSo_HoVaTen,
            chuHoSo_HuyenId: response.data.data.chuHoSo_HuyenId,
            chuHoSo_SoDienThoai: response.data.data.chuHoSo_SoDienThoai,
            chuHoSo_TinhId: response.data.data.chuHoSo_TinhId,
            chuHoSo_XaId: response.data.data.chuHoSo_XaId,

          }
          // this.danhSachDongBo = response.data.data.content
        }
      })
      this.$nextTick(() => {
        this.beginObject = _cloneDeep(this.dataForm)
        this.$refs.commonnModal.showModal()
      })
    },
    formatDate(value) {
      if (!_isEmpty(value)) { return moment(value).format('DD/MM/YYYY | HH:mm:ss') }
      return ''
    },
    formatDateTime(value) {
      if (!_isEmpty(value)) { return moment(value).format('HH:mm - DD/MM/YYYY') }
      return ''
    },
    pageChange() {
      const data = this.$refs.danhSachDongBo.getCurrentPage()
      const payload = {
        ...this.filter,
        pageNumber: data.pageNumber,
        pageSize: data.pageSize,
      }
      this.$axios.get(END_POINTS.DONG_BO.DANH_SACH,
        payload,
        false).then(response => {
        if (response.data) {
          this.tongSoDongBo = response.data.data.totalElements || 0
          this.danhSachDongBo = response.data.data.content
        }
      })
    },
    filterdanhSachDongBo(param = {}) {
      this.filter = {
        ...this.filter,
        ...param,
      }
      let payload = {
        ...this.filter,
      }
      if (this.$refs.danhSachDongBo) {
        payload = {
          ...payload,
          ...this.$refs.danhSachDongBo.getCurrentPage(),
        }
      }
      if (param.maTrangThai) {
        payload = {
          ...payload,
          pageNumber: 1,
          pageSize: this.filter.pageSize,
          maTrangThai: param.maTrangThai ? param.maTrangThai.replace(/\s+/g, ' ').trim() : '',
        }
      }
      if (param.tenTrangThai) {
        payload = {
          ...payload,
          pageNumber: 1,
          pageSize: this.filter.pageSize,
          tenTrangThai: param.tenTrangThai ? param.tenTrangThai.replace(/\s+/g, ' ').trim() : '',
        }
      }
      if (this.filter.status || !this.filter.status) {
        payload = {
          ...payload,
          pageNumber: 1,
          pageSize: this.filter.pageSize,
        }
      }
      if (this.filter.status === null) {
        delete payload.status
      }
      this.$axios.get(END_POINTS.DONG_BO.DANH_SACH, this.$trimObject({
        ...payload,
      }), false).then(response => {
        // console.log(response.data.data.content)
        if (response.data) {
          this.tongSoDongBo = response.data.data.totalElements || 0
          this.danhSachDongBo = response.data.data.content
        }
      })
    },
  },
}
</script>
