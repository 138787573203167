<template>
  <div>
    <b-form>
      <b-form>
        <b-row>
          <b-col cols="12"><b-form-group
            label="Tên thủ tục hành chính"
            label-for="tenTTHC"
          >
            <b-form-input
              id="tenTTHC"
              ref="tenTTHC"
              v-model="dataForm.tenTTHC"
              :disabled="true"
              type="text"
              placeholder="Nhập tên TTHC"
            />
          </b-form-group></b-col>
        </b-row>
        <b-row>
          <b-col cols="6"><b-form-group
            label="Phí"
            label-for="phiHs"
          >
            <b-form-input
              id="phiHs"
              ref="phiHs"
              v-model="dataForm.phiHs"
              :disabled="true"
              type="text"
              placeholder="Nhập phí"
            />
          </b-form-group></b-col>
          <b-col cols="6">
            <b-form-group
              label="Lệ phí"
              label-for="lePhi"
            >
              <b-form-input
                id="lePhi"
                ref="lePhi"
                v-model="dataForm.lePhi"
                :disabled="true"
                type="text"
                placeholder="Nhập lệ phí"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="6"><b-form-group
            label="Mã hồ sơ"
            label-for="code"
          >
            <b-form-input
              id="code"
              ref="code"
              v-model="dataForm.code"
              :disabled="true"
              type="text"
              placeholder="Nhập mã hồ sơ"
            />
          </b-form-group></b-col>
          <b-col cols="6">
            <b-form-group
              label="Số biên nhận"
              label-for="code"
            >
              <b-form-input
                id="code"
                ref="code"
                v-model="dataForm.code"
                type="text"
                :disabled="true"
                placeholder="Nhập số biên nhận"
              />

            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="6"><b-form-group
            label="Ngày tiếp nhận"
            label-for="ngayNop"
          >
            <b-form-input
              id="ngayNop"
              ref="ngayNop"
              v-model="dataForm.ngayNop"
              type="text"
              :disabled="true"
              placeholder="Nhập ngày tiếp nhận"
            />
          </b-form-group></b-col>
          <b-col cols="6">
            <b-form-group
              label="Ngày hẹn trả kết quả"
              label-for="ngayHenTraKq"
            >
              <b-form-input
                id="ngayHenTraKq"
                ref="ngayHenTraKq"
                v-model="dataForm.ngayHenTraKq"
                type="text"
                :disabled="true"
                placeholder="Nhập ngày hẹn trả"
              />

            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12"><b-form-group
            label="Trạng thái hồ sơ"
            label-for="tenTTHC"
          >
            <b-form-input
              id="trangThaiId"
              ref="trangThaiId"
              v-model="dataForm.trangThaiId"
              :disabled="true"
              type="text"
              placeholder="Nhập trạng thái"
            />
          </b-form-group>
          </b-col>
        </b-row>
        <div class="title-cm" />
        <b-row>
          <b-col cols="6"><b-form-group
            label="Người nộp"
            class="required"
            label-for="nguoiNop"
          >
            <b-form-input
              id="nguoiNop_HoVaTen"
              ref="nguoiNop_HoVaTen"
              v-model="dataForm.nguoiNop_HoVaTen"
              type="text"
              :disabled="true"
              placeholder="Nhập người nộp"
            />
          </b-form-group></b-col>
          <b-col cols="6">
            <b-form-group
              label="Chủ hồ sơ"
              class="required"
              label-for="chuHoSo_HoVaTen"
            >
              <b-form-input
                id="chuHoSo_HoVaTen"
                ref="chuHoSo_HoVaTen"
                v-model="dataForm.chuHoSo_HoVaTen"
                type="text"
                :disabled="true"
                placeholder="Nhập chủ hồ sơ"
              />

            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="6"><b-form-group
            label="Số CMND/CCCD"
            class="required"
            label-for="nguoiNop_CMND_HoChieu"
          >
            <b-form-input
              id="nguoiNop_CMND_HoChieu"
              ref="nguoiNop_CMND_HoChieu"
              v-model="dataForm.nguoiNop_CMND_HoChieu"
              type="text"
              :disabled="true"
              placeholder="Nhập CMND/CCCD"
            />
          </b-form-group></b-col>
          <b-col cols="6">
            <b-form-group
              label="Số CMND/CCCD"
              class="required"
              label-for="chuHoSo_CMND_HoChieu"
            >
              <b-form-input
                id="chuHoSo_CMND_HoChieu"
                ref="chuHoSo_CMND_HoChieu"
                v-model="dataForm.chuHoSo_CMND_HoChieu"
                type="text"
                :disabled="true"
                placeholder="Nhập CMND/CCCD"
              />

            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="6"><b-form-group
            label="Số điện thoại"
            class="required"
            label-for="nguoiNop_SoDienThoai"
          >
            <b-form-input
              id="nguoiNop_SoDienThoai"
              ref="nguoiNop_SoDienThoai"
              v-model="dataForm.nguoiNop_SoDienThoai"
              type="text"
              :disabled="true"
              placeholder="Nhập số điện thoại"
            />
          </b-form-group></b-col>
          <b-col cols="6">
            <b-form-group
              label="Số điện thoại"
              class="required"
              label-for="chuHoSo_SoDienThoai"
            >
              <b-form-input
                id="chuHoSo_SoDienThoai"
                ref="chuHoSo_SoDienThoai"
                v-model="dataForm.chuHoSo_SoDienThoai"
                type="text"
                :disabled="true"
                placeholder="Nhập số điện thoại"
              />

            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="6"><b-form-group
            label="Địa chỉ email"
            class="required"
            label-for="nguoiNop_DiaChiEmail"
          >
            <b-form-input
              id="nguoiNop_DiaChiEmail"
              ref="nguoiNop_DiaChiEmail"
              v-model="dataForm.nguoiNop_DiaChiEmail"
              type="text"
              :disabled="true"
              placeholder="Nhập địa chỉ email"
            />
          </b-form-group></b-col>
          <b-col cols="6">
            <b-form-group
              label="Địa chỉ email"
              label-for="chuHoSo_DiaChiEmail"
            >
              <b-form-input
                id="chuHoSo_DiaChiEmail"
                ref="chuHoSo_DiaChiEmail"
                v-model="dataForm.chuHoSo_DiaChiEmail"
                type="text"
                :disabled="true"
                placeholder="Nhập địa chỉ email"
              />

            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="6"><b-form-group
            label="Tỉnh/Thành phố"
            class="required"
            label-for="nguoiNop_TinhId"
          >
            <treeselect
              v-model="dataForm.nguoiNop_TinhId"
              v-format-v-select
              :options="tinhThanhPho"
              :limit="0"
              :limit-text="(count) => ` + ${count} lựa chọn`"
              no-options-text="Không có dữ liệu"
              no-results-text="Không có dữ liệu"
              placeholder=""
              :clear-on-select="true"
              :normalizer="normalizer"
              :disabled="true"
            />
          </b-form-group></b-col>
          <b-col cols="6"><b-form-group
            label="Tỉnh/Thành phố"
            class="required"
            label-for="chuHoSo_TinhId"
          >
            <treeselect
              v-model="dataForm.chuHoSo_TinhId"
              v-format-v-select
              :options="tinhThanhPho"
              :limit="0"
              :limit-text="(count) => ` + ${count} lựa chọn`"
              no-options-text="Không có dữ liệu"
              no-results-text="Không có dữ liệu"
              placeholder=""
              :clear-on-select="true"
              :normalizer="normalizer"
              :disabled="true"
            />
          </b-form-group></b-col>
        </b-row>
        <b-row>
          <b-col cols="6"><b-form-group
            label="Quận/Huyện"
            class="required"
            label-for="nguoiNop_HuyenId"
          >
            <!-- <treeselect
              v-model="dataForm.nguoiNop_HuyenId"
              v-format-v-select
              :options="quanHuyen"
              :limit="0"
              :limit-text="(count) => ` + ${count} lựa chọn`"
              no-options-text="Không có dữ liệu"
              no-results-text="Không có dữ liệu"
              placeholder=""
              :clear-on-select="true"
              :normalizer="normalizer"
              :disabled="true"
              @input="layDanhSachXaPhuong($event, {
                danhSachXa: 'xaPhuong',
                xaModel: 'nguoiNop_XaId',
              })"
            /> -->
            <treeselect
              v-model="dataForm.nguoiNop_HuyenId"
              v-format-v-select
              :options="quanHuyen"
              :limit="0"
              :limit-text="(count) => ` + ${count} lựa chọn`"
              no-options-text="Không có dữ liệu"
              no-results-text="Không có dữ liệu"
              placeholder=""
              :clear-on-select="true"
              :normalizer="normalizer"
              :disabled="true"
            />
          </b-form-group></b-col>
          <b-col cols="6"><b-form-group
            label="Quận/Huyện"
            class="required"
            label-for="chuHoSo_HuyenId"
          >
            <!-- <treeselect
              v-model="dataForm.chuHoSo_HuyenId"
              v-format-v-select
              :options="quanHuyen"
              :limit="0"
              :limit-text="(count) => ` + ${count} lựa chọn`"
              no-options-text="Không có dữ liệu"
              no-results-text="Không có dữ liệu"
              placeholder=""
              :clear-on-select="true"
              :normalizer="normalizer"
              :disabled="true"
              @input="layDanhSachXaPhuong($event, {
                danhSachXa: 'xaPhuong',
                xaModel: 'chuHoSo_XaId',
              })"
            /> -->
            <treeselect
              v-model="dataForm.chuHoSo_HuyenId"
              v-format-v-select
              :options="quanHuyen"
              :limit="0"
              :limit-text="(count) => ` + ${count} lựa chọn`"
              no-options-text="Không có dữ liệu"
              no-results-text="Không có dữ liệu"
              placeholder=""
              :clear-on-select="true"
              :normalizer="normalizer"
              :disabled="true"
            />
          </b-form-group></b-col>
        </b-row>
        <b-row>
          <b-col cols="6"><b-form-group
            label="Phường/Xã/Thị trấn"
            class="required"
            label-for="ngayNop"
          >
            <treeselect
              v-model="dataForm.nguoiNop_XaId"
              v-format-v-select
              :options="xaPhuong"
              :limit="0"
              :limit-text="(count) => ` + ${count} lựa chọn`"
              no-options-text="Không có dữ liệu"
              no-results-text="Không có dữ liệu"
              placeholder=""
              :clear-on-select="true"
              :normalizer="normalizer"
              :disabled="true"
            />
          </b-form-group></b-col>
          <b-col cols="6"><b-form-group
            label="Phường/Xã/Thị trấn"
            class="required"
            label-for="chuHoSo_XaId"
          >
            <treeselect
              v-model="dataForm.chuHoSo_XaId"
              v-format-v-select
              :options="xaPhuong"
              :limit="0"
              :limit-text="(count) => ` + ${count} lựa chọn`"
              no-options-text="Không có dữ liệu"
              no-results-text="Không có dữ liệu"
              placeholder=""
              :clear-on-select="true"
              :normalizer="normalizer"
              :disabled="true"
            />
          </b-form-group></b-col>
        </b-row>
        <b-row>
          <b-col cols="6"><b-form-group
            label="Địa chỉ"
            label-for="nguoiNop_DiaChi"
          >
            <b-form-input
              id="nguoiNop_DiaChi"
              ref="nguoiNop_DiaChi"
              v-model="dataForm.nguoiNop_DiaChi"
              type="text"
              :disabled="true"
              placeholder="Nhập địa chỉ chi tiết"
            />
          </b-form-group></b-col>
          <b-col cols="6"><b-form-group
            label="Địa chỉ"
            label-for="chuHoSo_DiaChi"
          >
            <b-form-input
              id="chuHoSo_DiaChi"
              ref="chuHoSo_DiaChi"
              v-model="dataForm.chuHoSo_DiaChi"
              type="text"
              :disabled="true"
              placeholder="Nhập địa chỉ chi tiết"
            />
          </b-form-group></b-col>
        </b-row>
      </b-form>
    </b-form></div>
</template>
<script>
import {
  BFormInput,
  BFormGroup,
  BRow,
  BCol,
  BForm,
  // BFormCheckbox,
  // BFormTextarea,
} from 'bootstrap-vue'

import { extend } from 'vee-validate'
import { required } from 'vee-validate/dist/rules'
import Treeselect from '@riophae/vue-treeselect'
import END_POINTS from '@/api/endpoints'

extend('required', {
  ...required,
  message: 'Vui lòng nhập thông tin',
})

export default {
  components: {
    BFormInput,
    BForm,
    BFormGroup,
    // BFormCheckbox,
    BRow,
    Treeselect,
    BCol,
    //  BFormTextarea,
  },
  props: {
    dataForm: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      errorTrungMa: null,
      tinhThanhPho: [],
      quanHuyen: [],
      xaPhuong: [],
    }
  },
  watch: {
    'dataForm.nguoiNop_TinhId': function (val) {
      this.layDanhSachQuanHuyen(val, {
        danhSachHuyen: 'quanHuyen',
        huyenModel: 'nguoiNop_HuyenId',
        xaModel: 'nguoiNop_XaId',
      })
    },
    'dataForm.chuHoSo_TinhId': function (val) {
      this.layDanhSachQuanHuyen(val, {
        danhSachHuyen: 'quanHuyen',
        huyenModel: 'chuHoSo_HuyenId',
        xaModel: 'chuHoSo_XaId',
      })
    },
    'dataForm.chuHoSo_HuyenId': function (val) {
      this.layDanhSachXaPhuong(val, {
        danhSachXa: 'xaPhuong',
        xaModel: 'chuHoSo_XaId',
      })
    },
    'dataForm.nguoiNop_HuyenId': function (val) {
      this.layDanhSachXaPhuong(val, {
        danhSachXa: 'xaPhuong',
        xaModel: 'nguoiNop_XaId',
      })
    },
  },
  created() {
    this.layDanhSachTinhThanhPho()
    this.layDanhSachQuanHuyen(this.dataForm.nguoiNop_TinhId, {
      danhSachHuyen: 'quanHuyen',
      huyenModel: 'nguoiNop_HuyenId',
      xaModel: 'nguoiNop_XaId',
    })
    this.layDanhSachQuanHuyen(this.dataForm.chuHoSo_TinhId, {
      danhSachHuyen: 'quanHuyen',
      huyenModel: 'chuHoSo_HuyenId',
      xaModel: 'chuHoSo_XaId',
    })
    this.layDanhSachXaPhuong(this.dataForm.chuHoSo_HuyenId, {
      danhSachXa: 'xaPhuong',
      xaModel: 'chuHoSo_XaId',
    })
    this.layDanhSachXaPhuong(this.dataForm.nguoiNop_HuyenId, {
      danhSachXa: 'xaPhuong',
      xaModel: 'nguoiNop_XaId',
    })
    // this.layDanhSachXaPhuong()
  },
  methods: {
    layDanhSachTinhThanhPho() {
      this.$axios.get(END_POINTS.COMMON.LAY_DON_VI, { level: 1 }).then(response => {
        if (response.data && response.data.succeeded) {
          this.tinhThanhPho = response.data.data
        }
      })
    },
    layDanhSachQuanHuyen(value, modelValue) {
      // if (!value) {
      //   this.dataForm[modelValue.huyenModel] = null
      //   this.dataForm[modelValue.xaModel] = null
      // } else {
      this.$axios.get(END_POINTS.COMMON.LAY_DON_VI, { level: 2, idDonViCha: value }).then(response => {
        if (response.data && response.data.succeeded) {
          this[modelValue.danhSachHuyen] = response.data.data
        }
      })
      //
    },
    layDanhSachXaPhuong(value, modelValue) {
      // if (!value) {
      //   this.dataForm[modelValue.xaModel] = null
      // } else {
      this.$axios.get(END_POINTS.COMMON.LAY_DON_VI, { level: 3, idDonViCha: value }).then(response => {
        if (response.data && response.data.succeeded) {
          this[modelValue.danhSachXa] = response.data.data
        }
      })
      // }
    },
    normalizer(node) {
      return {
        id: node.donViId || node.id,
        code: node.code || node.maTTHC,
        label: node.tenDonVi || node.name || node.tenMauHoSo || node.tenLinhVuc || node.tenTTHC,
      }
    },
  },
}
</script>
<style lang="scss" scoped>
.title-cm {
  font-size: 12px;
  color: #efefef;
  font-weight: 600;
  border-bottom: 1px solid #efefef;
  padding-bottom: 10px;
  margin-bottom: 15px;
}
</style>
